import * as React from 'react'
// @ts-ignore
import styled from 'styled-components'
import Layout from '../components/Layout/Layout'
import { Color } from '@/theme/colors'

// @ts-ignore
import Example1 from '@/assets/images/example1.jpg'
import {
  Bolder,
  Header1,
  Header2,
  Header3,
  Paragraph,
} from '@/theme/Typography.styled'
import { Container } from '@/theme/Grid.styled'
import Banner1 from '@/components/Banner1/Banner1'
import Image1 from '@/assets/images/psie-przedszkole.png'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${Color.Pink_1};
  text-align: center;

  img {
    width: 600px;
    max-width: 90%;
    margin: 30px auto;
  }
`

const Description = styled.div`
  padding: 50px 0;

  img {
    margin: 50px 0;
  }

  @media (max-width: 991px) {
    padding: 0;

    img {
      margin: 15px 0;
    }
  }
`

const Banner = styled.div`
  background-color: ${Color.Pink_2};
  padding: 50px 0;

  @media (max-width: 991px) {
    padding: 30px 0;
  }
`

const BannerInner = styled.div`
  display: grid;
  grid-row-gap: 15px;

  @media (max-width: 991px) {
    grid-row-gap: 0;
  }
`

const Pricing = styled.div`
  display: grid;
  grid-row-gap: 30px;
  justify-content: center;
  align-content: center;
  padding: 50px 0 70px;

  @media (max-width: 991px) {
    grid-row-gap: 15px;
    padding: 30px 0;
  }
`

const Head = () => (
  <>
    <title>Pet School - Psie przedszkole</title>
  </>
)

const DogBehavioralConsultationsPage = () => {
  return (
    <>
      <Head />
      <Layout>
        <Wrapper>
          <Container>
            <img alt="Pet School - psie przedszkole" src={Image1} />

            <Description>
              <Header2 marginBottom={39}>
                <Bolder>Psie przedszkole</Bolder>
              </Header2>

              <Paragraph>
                Zajęcia skierowane są dla szczeniąt w wieku od 2* do 5 miesięcy
                (*po drugiej dawce szczepień na choroby zakaźne).
              </Paragraph>

              <Paragraph>
                Spotkania odbywają się w grupie od 2 do 4 szczeniąt na
                ogrodzonym placu treningowym przy ulicy Mącznej oraz jeden
                spacer socjalizacyjny na wałach na Praczach Odrzańskich.
              </Paragraph>
            </Description>
          </Container>

          <Banner>
            <Container>
              <BannerInner>
                <Header2>
                  <Bolder> Na kursie między innymi:</Bolder>
                </Header2>

                <Paragraph>- metody motywacji u psa</Paragraph>
                <Paragraph>- nauka prawidłowej zabawy i odpoczynku</Paragraph>
                <Paragraph>- podstawy psiej komunikacji</Paragraph>
                <Paragraph>- habituacja i socjalizacja</Paragraph>
                <Paragraph>- nauka ignorowania psów i ludzi</Paragraph>
                <Paragraph>- jak prawidłowo dotykać psa</Paragraph>
                <Paragraph>
                  - jak prawidłowo przeprowadzić trening medyczny
                </Paragraph>
                <Paragraph>- nauka chodzenia na luźnej smyczy</Paragraph>
                <Paragraph>
                  - podstawowe komendy takie jak: siad, leżeć, do mnie, komenda
                  zwalniającą, skupienie uwagi na przewodniku, odsyłanie na
                  miejsce, różne sztuczki, podążanie za przewodnikiem
                </Paragraph>
              </BannerInner>
            </Container>
          </Banner>

          <Banner1>
            <Header3>
              <Bolder>
                Po każdych zajęciach uczestnicy otrzymują materiały teoretyczne.
              </Bolder>
            </Header3>
          </Banner1>

          <Container>
            <Pricing>
              <div>
                <Paragraph>Czas trwania zajęć</Paragraph>
                <Header1>
                  <Bolder>60 minut 1x w tygodniu (łącznie 6 spotkań)</Bolder>
                </Header1>
              </div>

              <div>
                <Paragraph>Koszt kursu</Paragraph>
                <Header1>
                  <Bolder>450 zł</Bolder>
                </Header1>
              </div>
            </Pricing>
          </Container>
        </Wrapper>
      </Layout>
    </>
  )
}

export default DogBehavioralConsultationsPage
